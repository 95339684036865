.App {
  background-color: black;
  background: url(./assets/hellcarsbackground.webp) no-repeat center center
    fixed;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
